import { Button, ThemeProvider } from '@mui/material'
import { theme } from './theme'

import s from './ReportFilters.module.css'
import { getFindTypeOptions } from 'features/assessment/find/lib'
import { Option } from 'features/assessment/find/types'
import { ReportFilterType } from 'widgets/report/types'

const allOption: Option = {
  name: 'Все',
  value: 'all',
}

interface ReportFiltersProps {
  filter: ReportFilterType
  setFilter: (value: ReportFilterType) => void
}

export const ReportFilters = ({ filter, setFilter }: ReportFiltersProps) => {
  const options = [allOption, ...getFindTypeOptions()]

  return (
    <ThemeProvider theme={theme}>
      <div className={s.wrapper}>
        <div className={s.buttons}>
          {options.map((option) => (
            <Button
              className={s.button}
              variant={filter === option.value ? 'contained' : 'outlined'}
              key={option.name}
              onClick={() => setFilter(option.value as ReportFilterType)}
            >
              {option.name}
            </Button>
          ))}
        </div>
      </div>
    </ThemeProvider>
  )
}
