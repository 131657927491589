import SearchIcon from '@mui/icons-material/Search'
import { useTranslation } from 'react-i18next'
import s from './AssessmentFragment.module.css'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
  ScreenFragment,
  getAssessmentAttributes,
  useDeleteScreenFragment,
  useUpdateScreenFragmentName,
} from 'entities/assessment'
import classNames from 'classnames'
import { useState } from 'react'
import { IconButton, Menu, MenuItem } from '@mui/material'
import { useHasWritePermission } from 'entities/permissions'
import i18n from 'shared/i18n/i18n'
import { useProjectContext } from 'features/head/context'

interface AssessmentFragmentProps {
  fragment: ScreenFragment
  screenId: string
  onSearchClick: VoidFunction
  onResultsClick: VoidFunction
  onClear: VoidFunction
  onClick?: VoidFunction
}

export const AssessmentFragment = ({
  fragment,
  screenId,
  onResultsClick,
  onSearchClick,
  onClick,
  onClear,
}: AssessmentFragmentProps) => {
  const { t } = useTranslation()

  const [fragmentName, setFragmentName] = useState<string>(fragment.name)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isMenuOpen = Boolean(anchorEl)
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const goodUxAssessmentsCount =
    fragment.assessments?.filter((item) => item.findType === 'ux-good')
      .length ?? 0
  const problemUxAssessmentsCount =
    fragment.assessments?.filter((item) => item.findType === 'ux-problem')
      .length ?? 0
  const taskAssessmentsCount =
    fragment.assessments?.filter((item) => item.findType === 'task').length ?? 0
  const bugAssessmentsCount =
    fragment.assessments?.filter((item) => item.findType === 'bug').length ?? 0

  const updateScreenFragmentName = useUpdateScreenFragmentName()

  const hasWritePermission = useHasWritePermission()

  const updateFragmentName = () =>
    hasWritePermission
      ? updateScreenFragmentName({
          fragmentId: fragment.id,
          screenId,
          name: fragmentName,
        })
      : undefined

  const deleteScreenFragment = useDeleteScreenFragment()

  const fragmentTypeLabel = fragment.root ? t('mainScreen') : t('fragment')

  const { isAssessment } = useProjectContext()

  return (
    <div
      onClick={onClick}
      className={classNames(s.header, {
        [s.clickable]: Boolean(onClick),
      })}
    >
      <input
        className={classNames(s.input, {
          [s.inputReadonly]: Boolean(onClick),
        })}
        onBlur={updateFragmentName}
        value={fragmentName}
        onChange={
          isAssessment
            ? undefined
            : (e) =>
                hasWritePermission ? setFragmentName(e.target.value) : undefined
        }
        onKeyDown={(e) => {
          if (e.code === 'Enter' || e.code === 'NumpadEnter') {
            e.currentTarget.blur()
          }
        }}
        minLength={1}
      />
      <div className={s.fragmentTypeLabel}>{fragmentTypeLabel}</div>
      <div className={s.fragmentActions}>
        {hasWritePermission && isAssessment && (
          <div onClick={onSearchClick}>
            <div className={s.fragmentAction}>
              <SearchIcon fontSize="small" />
            </div>
          </div>
        )}

        {isAssessment && (
          <div onClick={onResultsClick} className={s.fragmentStats}>
            <div
              className={s.fragmentAction}
              style={{
                backgroundColor: getAssessmentAttributes()['ux-good'].color,
              }}
            >
              {goodUxAssessmentsCount}
            </div>
            <div
              className={s.fragmentAction}
              style={{
                backgroundColor: getAssessmentAttributes()['ux-problem'].color,
              }}
            >
              {problemUxAssessmentsCount}
            </div>
            <div
              className={s.fragmentAction}
              style={{
                backgroundColor: getAssessmentAttributes().task.color,
              }}
            >
              {taskAssessmentsCount}
            </div>
            <div
              className={s.fragmentAction}
              style={{
                backgroundColor: getAssessmentAttributes().bug.color,
              }}
            >
              {bugAssessmentsCount}
            </div>
          </div>
        )}

        {hasWritePermission && (
          <IconButton
            className={classNames({
              [s.disabledDeleteButton]: fragment.root,
            })}
            title={i18n.t('transitionOptions')}
            onClick={handleMenuClick}
          >
            <MoreVertIcon />
          </IconButton>
        )}

        <Menu
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={(e: Event) => {
            e.stopPropagation()
            setAnchorEl(null)
          }}
        >
          <MenuItem
            onClick={() => {
              deleteScreenFragment({
                screenId,
                fragmentId: fragment.id,
                onSuccess: () => {
                  onClear()
                  setAnchorEl(null)
                },
              })
            }}
          >
            {i18n.t('remove')}
          </MenuItem>
        </Menu>
      </div>
    </div>
  )
}
