import { fetchHeuristics } from 'entities/assessment/api'
import { fetchRemoteProject } from 'shared/api/projects'
import {
  Report,
  ReportAssessments,
  ReportScreenVariant,
  ReportScreenVariantFragment,
} from './types'
import {
  DEFAULT_OFFSET,
  TRANSITION_WIDTH,
  getNearestActionIdInColumn,
} from 'entities/flow'
import i18n from 'shared/i18n/i18n'

interface GenerateReportParams {
  projectId: string
}

const getInitialReportAssessments = (): ReportAssessments => ({
  'ux-good': 0,
  'ux-problem-high': 0,
  'ux-problem-low': 0,
  'ux-problem-medium': 0,
  bug: 0,
  task: 0,
})

export const generateReport = async ({
  projectId,
}: GenerateReportParams): Promise<Report> => {
  const project = await fetchRemoteProject({ projectId })
  const heuristics = await fetchHeuristics()

  const reportScreens: Report['screens'] = []

  const screenXCoords = new Set(
    project.flow.screens
      .filter((screen) => Boolean(screen.name))
      .map((screen) => screen.position.x)
  )
  const sortedUniqScreenXCoords = [...screenXCoords.keys()].sort(
    (a, b) => a - b
  )

  sortedUniqScreenXCoords.forEach((xCoord, index) => {
    const currentXScreens = project.flow.screens
      .filter((screen) => screen.position.x === xCoord && Boolean(screen.name))
      .sort((screenA, screenB) => screenA.position.y - screenB.position.y)

    const firstScreen = currentXScreens[0]

    const leftTransitionFromFirstScreen = project.flow.transitions.find(
      (transition) =>
        transition.position.y === firstScreen.position.y &&
        transition.position.x ===
          firstScreen.position.x - DEFAULT_OFFSET - TRANSITION_WIDTH
    )

    const screenVariants: ReportScreenVariant[] = currentXScreens.map(
      (screen, index) => {
        const nearestActionId = getNearestActionIdInColumn({
          transitions: project.flow.transitions,
          position: {
            x: screen.position.x - DEFAULT_OFFSET - TRANSITION_WIDTH,
            y: screen.position.y - 1,
          },
        })

        const actionName = project.transitionComponents.find(
          (component) => component.id === nearestActionId
        )?.name

        const fragments: ReportScreenVariantFragment[] =
          project.screenFragments[screen.id]?.map((screenFragment, index) => {
            const fragmentTotalAssessments: ReportAssessments = {
              'ux-good': screenFragment.assessments.filter(
                (assessment) => assessment.findType === 'ux-good'
              ).length,
              'ux-problem-high': screenFragment.assessments.filter(
                (assessment) =>
                  assessment.type === 'bad' &&
                  assessment.findType === 'ux-problem'
              ).length,
              'ux-problem-low': screenFragment.assessments.filter(
                (assessment) =>
                  assessment.type === 'good' &&
                  assessment.findType === 'ux-problem'
              ).length,
              'ux-problem-medium': screenFragment.assessments.filter(
                (assessment) =>
                  assessment.type === 'medium' &&
                  assessment.findType === 'ux-problem'
              ).length,
              bug: screenFragment.assessments.filter(
                (assessment) => assessment.findType === 'bug'
              ).length,
              task: screenFragment.assessments.filter(
                (assessment) => assessment.findType === 'task'
              ).length,
            }

            return {
              ...screenFragment,
              number: index + 1,
              totalAssessments: fragmentTotalAssessments,
            }
          })

        const screenVariantTotalAssessments = getInitialReportAssessments()

        fragments.forEach((fragment) => {
          screenVariantTotalAssessments.bug += fragment.totalAssessments.bug
          screenVariantTotalAssessments.task += fragment.totalAssessments.task
          screenVariantTotalAssessments['ux-good'] +=
            fragment.totalAssessments['ux-good']
          screenVariantTotalAssessments['ux-problem-high'] +=
            fragment.totalAssessments['ux-problem-high']
          screenVariantTotalAssessments['ux-problem-medium'] +=
            fragment.totalAssessments['ux-problem-medium']
          screenVariantTotalAssessments['ux-problem-low'] +=
            fragment.totalAssessments['ux-problem-low']
        })

        return {
          screenId: screen.id,
          number: index + 1,
          actionName: actionName || '',
          name: index === 0 ? i18n.t('mainScreen') : screen.name,
          fragments: fragments.filter(
            (item) =>
              item.totalAssessments.bug ||
              item.totalAssessments.task ||
              item.totalAssessments['ux-problem-high'] ||
              item.totalAssessments['ux-problem-medium'] ||
              item.totalAssessments['ux-problem-low'] ||
              item.totalAssessments['ux-good']
          ),
          totalAssessments: screenVariantTotalAssessments,
        }
      }
    )

    const reportScreenTotalAssessments = getInitialReportAssessments()

    screenVariants.forEach((screenVariant) => {
      reportScreenTotalAssessments.bug += screenVariant.totalAssessments.bug
      reportScreenTotalAssessments.task += screenVariant.totalAssessments.task
      reportScreenTotalAssessments['ux-good'] +=
        screenVariant.totalAssessments['ux-good']
      reportScreenTotalAssessments['ux-problem-high'] +=
        screenVariant.totalAssessments['ux-problem-high']
      reportScreenTotalAssessments['ux-problem-medium'] +=
        screenVariant.totalAssessments['ux-problem-medium']
      reportScreenTotalAssessments['ux-problem-low'] +=
        screenVariant.totalAssessments['ux-problem-low']
    })

    if (
      reportScreenTotalAssessments.bug ||
      reportScreenTotalAssessments.task ||
      reportScreenTotalAssessments['ux-problem-high'] ||
      reportScreenTotalAssessments['ux-problem-medium'] ||
      reportScreenTotalAssessments['ux-problem-low'] ||
      reportScreenTotalAssessments['ux-good']
    ) {
      reportScreens.push({
        name: firstScreen.name,
        actionName:
          project.transitionComponents.find(
            (transitionComponent) =>
              transitionComponent.id === leftTransitionFromFirstScreen?.actionId
          )?.name || '',
        number: index + 1,
        screenVariants: screenVariants.filter(
          (item) =>
            item.totalAssessments.bug ||
            item.totalAssessments.task ||
            item.totalAssessments['ux-problem-high'] ||
            item.totalAssessments['ux-problem-medium'] ||
            item.totalAssessments['ux-problem-low'] ||
            item.totalAssessments['ux-good']
        ),
        totalAssessments: reportScreenTotalAssessments,
      })
    }
  })

  const totalAssessments: ReportAssessments = getInitialReportAssessments()

  reportScreens.forEach((reportScreen) => {
    totalAssessments.bug += reportScreen.totalAssessments.bug
    totalAssessments.task += reportScreen.totalAssessments.task
    totalAssessments['ux-good'] += reportScreen.totalAssessments['ux-good']
    totalAssessments['ux-problem-high'] +=
      reportScreen.totalAssessments['ux-problem-high']
    totalAssessments['ux-problem-medium'] +=
      reportScreen.totalAssessments['ux-problem-medium']
    totalAssessments['ux-problem-low'] +=
      reportScreen.totalAssessments['ux-problem-low']
  })

  const report: Report = {
    heuristics,
    projectId,
    screens: reportScreens,
    totalAssessments,
    project,
  }

  return report
}
