import { Report } from '../types'
import classNames from 'classnames'
import { Typography } from '@mui/material'
import { isEnglishProject } from 'shared/lib/language'
import { Assessment } from 'entities/assessment'
import PestControlIcon from '@mui/icons-material/PestControl'
import LightbulbIcon from '@mui/icons-material/Lightbulb'

import s from './ReportFind.module.css'

interface ReportFindProps {
  projectId: string
  heuristics: Report['heuristics']
  assessment: Assessment
  active: boolean
  onClick: VoidFunction
}

export const ReportFind = ({
  heuristics,
  active,
  assessment,
  onClick,
  projectId,
}: ReportFindProps) => {
  if (!assessment.id) {
    return null
  }

  const isEnglish = isEnglishProject(projectId)

  const heuristic = heuristics.find(
    (item) => item.id === assessment.heuristicId
  )

  const negativeName = isEnglish
    ? heuristic?.field_negative_name_en
    : heuristic?.field_negative_name_ru

  const thirdLevelTitle = isEnglish
    ? heuristic?.field_heuristic_3_level_en
    : heuristic?.title

  const heuristicName =
    assessment.findType === 'ux-problem' ? negativeName : thirdLevelTitle

  const name = assessment.name || heuristicName

  const renderIcon = () => {
    if (assessment.findType === 'bug') {
      return <PestControlIcon className={s.icon} />
    }

    if (assessment.findType === 'task') {
      return <LightbulbIcon className={s.icon} />
    }

    return (
      <div
        className={classNames(s.circle, {
          [s.good]: assessment.findType === 'ux-good',
          [s.medium]:
            assessment.findType === 'ux-problem' &&
            assessment.type === 'medium',
          [s.bad]:
            assessment.findType === 'ux-problem' && assessment.type === 'bad',
          [s.low]:
            assessment.findType === 'ux-problem' && assessment.type === 'good',
        })}
      />
    )
  }

  return (
    <div
      onClick={onClick}
      className={classNames(s.reportHeuristic, {
        [s.active]: active,
      })}
      id={`assessment-${assessment.id}`}
    >
      {renderIcon()}
      <Typography variant="h6" className={s.title}>
        {name}
      </Typography>
    </div>
  )
}
