import { useEffect, useState } from 'react'
import styles from './AssessmentPanel.module.css'
import { Button, Tab, Tabs } from '@mui/material'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { useHeuristics, useScreenFragments } from 'entities/assessment'
import { HeuristicSearch } from 'features/assessment/heuristic/search'
import { AssessmentPanelFilter } from 'features/assessment/filter'
import {
  getAssessmentFilterAttributes,
  getHeuristicFilterAttributes,
} from '../config'
import { AssessmentFragment } from 'features/assessment/fragment'
import { TabType } from 'pages/assessment'
import { useHasWritePermission } from 'entities/permissions'
import i18n from 'shared/i18n/i18n'
import { useProjectContext } from 'features/head/context'
import { FindForm } from 'features/assessment/find/FindForm'

interface AssessmentPanelProps {
  screenId: string
  fragmentId: string
  setFragmentId: (value: string) => void
  tab: TabType
  setTab: (tab: TabType) => void
  projectId: string
  activeHeuristicId: string
  setActiveHeuristicId: (value: string) => void
}

export const AssessmentPanel = ({
  screenId,
  fragmentId,
  setFragmentId,
  tab,
  setTab,
  projectId,
  activeHeuristicId,
  setActiveHeuristicId,
}: AssessmentPanelProps) => {
  const [selectedAssessmentFilters, selectAssessmentFilters] = useState<
    string[]
  >(['all'])

  const fragments = useScreenFragments({ screenId })

  const hasWritePermission = useHasWritePermission()

  useEffect(() => {
    if (fragments?.length === 1) {
      const rootFragment = fragments.find((item) => item.root)

      if (rootFragment) {
        setFragmentId(rootFragment.id)
      }
    }
  }, [fragments, setFragmentId])

  const fragment = fragments?.find((item) => item.id === fragmentId)

  const filteredAssessments = fragment?.assessments?.filter((assessment) => {
    const filterCondition = selectedAssessmentFilters.includes('all')
      ? true
      : selectedAssessmentFilters.includes(assessment.findType)

    return filterCondition
  })

  const { isAssessment } = useProjectContext()

  return (
    <div className={styles.wrapper}>
      {fragmentId ? (
        <>
          <Button
            variant="text"
            startIcon={<KeyboardBackspaceIcon />}
            sx={{
              mb: 2,
            }}
            onClick={() => setFragmentId('')}
          >
            {i18n.t('resultingPageForTheScreen')}
          </Button>
          {fragment && (
            <AssessmentFragment
              key={fragment.id}
              fragment={fragment}
              screenId={screenId}
              onResultsClick={() => setTab('results')}
              onSearchClick={() => setTab('search')}
              onClear={() => setFragmentId('')}
            />
          )}
          {isAssessment && (
            <Tabs
              value={tab}
              sx={{
                mt: 2,
                mb: 2,
              }}
              onChange={(_, newSelectedActionTab) =>
                setTab(newSelectedActionTab)
              }
            >
              {hasWritePermission && (
                <Tab
                  value="search"
                  label={i18n.t('heuristicSearchAndAssessment')}
                />
              )}
              <Tab
                value="results"
                label={`${i18n.t('assessmentResults')} (${
                  fragment?.assessments?.length ?? 0
                })`}
              />
            </Tabs>
          )}

          {tab === 'search' && isAssessment && (
            <>
              <FindForm
                activeHeuristicId={activeHeuristicId}
                setActiveHeuristicId={setActiveHeuristicId}
                screenId={screenId}
                fragmentId={fragmentId}
              />
            </>
          )}
          {tab === 'results' && isAssessment && (
            <>
              <div className={styles.searchAndFilter}>
                <AssessmentPanelFilter
                  selectedFilters={selectedAssessmentFilters}
                  selectFilters={selectAssessmentFilters}
                  filterAttributes={
                    fragment?.assessments
                      ? getAssessmentFilterAttributes(fragment.assessments)
                      : []
                  }
                  multiple
                />
              </div>

              {filteredAssessments?.map((assessment) => (
                <FindForm
                  key={assessment.id}
                  assessment={assessment}
                  activeHeuristicId={activeHeuristicId}
                  setActiveHeuristicId={setActiveHeuristicId}
                  screenId={screenId}
                  fragmentId={fragmentId}
                  onListPlacement
                />
              ))}
            </>
          )}
        </>
      ) : (
        <>
          {fragments?.map((fragment) => (
            <AssessmentFragment
              key={fragment.id}
              fragment={fragment}
              onClick={() => setFragmentId(fragment.id)}
              onResultsClick={() => setTab('results')}
              onSearchClick={() => setTab('search')}
              screenId={screenId}
              onClear={() => setFragmentId('')}
            />
          ))}
        </>
      )}
    </div>
  )
}
