export const getHeightPercentByCount = (count: number) => {
  if (!count) return 0

  if (count >= 1 && count <= 5) return 20

  if (count >= 6 && count <= 10) return 40

  if (count >= 11 && count <= 20) return 60

  if (count >= 21 && count <= 50) return 80

  return 100
}
