import { ReportAssessments } from '../types'
import classNames from 'classnames'

import s from './ReportAssessment.module.css'

interface ReportAssessmentProps {
  assessments: ReportAssessments
  active: boolean
  total?: boolean
}

export const ReportAssessment = ({
  assessments,
  active,
  total,
}: ReportAssessmentProps) => {
  return (
    <div
      className={classNames(s.reportAssessment, {
        [s.active]: active,
        [s.total]: total,
      })}
    >
      <div className={classNames(s.reportAssessmentItem, s['ux-good'])}>
        {assessments['ux-good'] || '—'}
      </div>
      <div className={classNames(s.reportAssessmentItem, s['ux-problem-low'])}>
        {assessments['ux-problem-low'] || '—'}
      </div>
      <div
        className={classNames(s.reportAssessmentItem, s['ux-problem-medium'])}
      >
        {assessments['ux-problem-medium'] || '—'}
      </div>
      <div className={classNames(s.reportAssessmentItem, s['ux-problem-high'])}>
        {assessments['ux-problem-high'] || '—'}
      </div>
      <div className={classNames(s.reportAssessmentItem, s.task)}>
        {assessments.task || '—'}
      </div>
      <div className={classNames(s.reportAssessmentItem, s.bug)}>
        {assessments.bug || '—'}
      </div>
    </div>
  )
}
