import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useProjectId } from 'shared/model/projects'
import { generateReport } from '../model'
import { Report, ReportDetailsCoord, ReportFilterType } from '../types'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import s from './ReportPage.module.css'
import { ReportAssessment } from './ReportAssessment'
import { ReportFragment } from './ReportFragment/ReportFragment'
import { ReportDetails } from './ReportDetails/ReportDetails'
import { ScenarioInfo } from './ScenarioInfo'
import { RemoteProjectMeta } from 'shared/types/projects'
import { useNavigate, useParams } from 'react-router-dom'
import { ReportAssessmentFilter } from './ReportAssessmentFilter/ReportAssessmentFilter'
import { ReportFilters } from './ReportFilters/ReportFilters'
import { ProjectHead } from 'features/head/ProjectHead'

interface RouteParams {
  screenNumber: string | undefined
  screenVariantNumber: string | undefined
  screenVariantFragmentNumber: string | undefined
  assessmentId: string | undefined
  [key: string]: string | undefined
}

const usePreparedRouteParams = () => {
  const {
    screenNumber,
    assessmentId,
    screenVariantNumber,
    screenVariantFragmentNumber,
  } = useParams<RouteParams>()

  return {
    screenNumber: Number(screenNumber),
    screenVariantNumber: Number(screenVariantNumber),
    screenVariantFragmentNumber: Number(screenVariantFragmentNumber),
    assessmentId: String(assessmentId),
  }
}

export const ReportPage = () => {
  const projectId = useProjectId()
  const [report, setReport] = useState<Report | null>(null)

  const [assessmentFilter, setAssessmentFilter] =
    useState<ReportFilterType>('all')

  const { t } = useTranslation()

  const navigate = useNavigate()

  const {
    screenNumber,
    assessmentId,
    screenVariantNumber,
    screenVariantFragmentNumber,
  } = usePreparedRouteParams()

  useEffect(() => {
    if (projectId) {
      const saveGeneratedReport = async () => {
        const report = await generateReport({ projectId })

        setReport(report)
      }

      saveGeneratedReport()
    }
  }, [projectId])

  useEffect(() => {
    if (report && assessmentId) {
      document.getElementById(`assessment-${assessmentId}`)?.scrollIntoView()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report])

  useEffect(() => {
    if (projectId && assessmentFilter !== 'all') {
      navigate(`/r/${projectId}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentFilter, projectId])

  if (!report) {
    return (
      <div className={s.wrapper}>
        <ProjectHead />
      </div>
    )
  }

  const totalAssessmentsCount =
    report.totalAssessments.task +
    report.totalAssessments.bug +
    report.totalAssessments['ux-problem-high'] +
    report.totalAssessments['ux-problem-medium'] +
    report.totalAssessments['ux-problem-low'] +
    report.totalAssessments['ux-good']

  const reportDetailsCoord: ReportDetailsCoord | null =
    screenNumber &&
    screenVariantNumber &&
    screenVariantFragmentNumber &&
    assessmentId
      ? {
          assessmentId,
          reportScreenNumber: screenNumber,
          reportScreenVariantNumber: screenVariantNumber,
          reportScreenVariantFragmentNumber: screenVariantFragmentNumber,
        }
      : null

  const encodedProjectMeta = localStorage.getItem(`metaData-${projectId}`)
  const decodedProjectMeta: RemoteProjectMeta | null = encodedProjectMeta
    ? JSON.parse(encodedProjectMeta)
    : null
  const isMobile =
    (decodedProjectMeta?.platform.includes('obile') ||
      decodedProjectMeta?.platform.includes('обил')) ??
    false

  const isAllFilter = assessmentFilter === 'all'

  return (
    <div className={s.wrapper}>
      <ProjectHead />
      <div className={s.navigationAndDetailsWrapper}>
        <div className={s.navigation}>
          <div>
            <ReportFilters
              filter={assessmentFilter}
              setFilter={setAssessmentFilter}
            />
            {report.screens.map((screen) => {
              if (!isAllFilter) {
                const isProblemFilter = assessmentFilter === 'ux-problem'
                let filterCount: number

                if (isProblemFilter) {
                  filterCount =
                    screen.totalAssessments['ux-problem-high'] +
                    screen.totalAssessments['ux-problem-low'] +
                    screen.totalAssessments['ux-problem-medium']
                } else {
                  filterCount = screen.totalAssessments[assessmentFilter]
                }

                if (!filterCount) return null
              }

              return (
                <Accordion
                  key={screen.number}
                  expanded={screenNumber === screen.number}
                  onChange={() =>
                    navigate(
                      screenNumber === screen.number
                        ? `/r/${projectId}`
                        : `/r/${screen.number}/${projectId}`
                    )
                  }
                  style={{
                    borderRadius: 0,
                    marginBottom: 0,
                    marginTop: '1px',
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        style={
                          screenNumber === screen.number
                            ? {
                                fill: '#2C9BF0',
                              }
                            : undefined
                        }
                      />
                    }
                    style={{
                      borderBottom: '1px solid rgba(0,0,0,0.3)',
                      flexFlow: 'row-reverse',
                    }}
                    className={s.accordionSummary}
                    classes={{
                      content: s.accordionSummaryContent,
                    }}
                  >
                    <div>
                      <Typography variant="subtitle2" color="gray">
                        #{screen.number}.0 {screen.actionName}
                      </Typography>
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        className={s.title}
                      >
                        {screen.name}
                      </Typography>
                    </div>
                    <ReportAssessmentFilter
                      assessmentFilter={assessmentFilter}
                      assessments={screen.totalAssessments}
                    />
                  </AccordionSummary>
                  <AccordionDetails className={s.accordionDetails}>
                    {screen.screenVariants.map((screenVariant) => {
                      if (!isAllFilter) {
                        const isProblemFilter =
                          assessmentFilter === 'ux-problem'
                        let filterCount: number

                        if (isProblemFilter) {
                          filterCount =
                            screenVariant.totalAssessments['ux-problem-high'] +
                            screenVariant.totalAssessments['ux-problem-low'] +
                            screenVariant.totalAssessments['ux-problem-medium']
                        } else {
                          filterCount =
                            screenVariant.totalAssessments[assessmentFilter]
                        }

                        if (!filterCount) return null
                      }

                      return (
                        <Accordion
                          style={{
                            borderRadius: 0,
                            marginBottom: 0,
                            marginTop: '1px',
                          }}
                          className={s.coloredSection}
                          key={screenVariant.number}
                          expanded={
                            screenVariantNumber === screenVariant.number
                          }
                          onChange={() =>
                            navigate(
                              screenVariantNumber === screenVariant.number
                                ? `/r/${screenNumber}/${projectId}`
                                : `/r/${screenNumber}/${screenVariant.number}/${projectId}`
                            )
                          }
                        >
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon
                                style={
                                  screenVariantNumber === screenVariant.number
                                    ? {
                                        fill: '#2C9BF0',
                                      }
                                    : undefined
                                }
                              />
                            }
                            style={{
                              borderBottom: '1px solid rgba(0,0,0,0.3)',
                              flexFlow: 'row-reverse',
                            }}
                            className={s.accordionSummary}
                            classes={{
                              content: s.accordionSummaryContent,
                            }}
                          >
                            <div>
                              <Typography variant="subtitle2" color="gray">
                                #{screen.number}.{screenVariant.number}{' '}
                                {screenVariant.actionName}
                              </Typography>
                              <Typography variant="h6" className={s.title}>
                                {screenVariant.name}
                              </Typography>
                            </div>
                            <ReportAssessmentFilter
                              assessmentFilter={assessmentFilter}
                              assessments={screenVariant.totalAssessments}
                            />
                          </AccordionSummary>
                          <AccordionDetails className={s.accordionDetails}>
                            {screenVariant.fragments.map((fragment) => (
                              <ReportFragment
                                assessmentFilter={assessmentFilter}
                                setSelectedAssessmentId={(newId) => {
                                  navigate(
                                    newId
                                      ? `/r/${screenNumber}/${screenVariantNumber}/${fragment.number}/${newId}/${projectId}`
                                      : `/r/${screenNumber}/${screenVariantNumber}/${fragment.number}/${projectId}`
                                  )
                                }}
                                selectedAssessmentId={assessmentId}
                                report={report}
                                onExpandedChange={() =>
                                  navigate(
                                    screenVariantFragmentNumber ===
                                      fragment.number
                                      ? `/r/${screenNumber}/${screenVariantNumber}/${projectId}`
                                      : `/r/${screenNumber}/${screenVariantNumber}/${fragment.number}/${projectId}`
                                  )
                                }
                                key={fragment.number}
                                fragment={fragment}
                                formattedNumber={`#${screen.number}.${screenVariant.number}.${fragment.number}`}
                                expanded={
                                  screenVariantFragmentNumber ===
                                  fragment.number
                                }
                              />
                            ))}
                          </AccordionDetails>
                        </Accordion>
                      )
                    })}
                  </AccordionDetails>
                </Accordion>
              )
            })}
          </div>

          <div className={s.totalAssessments}>
            <Typography className={s.title}>
              {t('ratings')}: {totalAssessmentsCount}
            </Typography>
            <ReportAssessment
              active={false}
              assessments={report.totalAssessments}
              total
            />
          </div>
        </div>
        <ReportDetails
          coord={reportDetailsCoord}
          report={report}
          isMobile={isMobile}
        />
      </div>
    </div>
  )
}
