import { useMutation, useQuery } from '@tanstack/react-query'
import { useCallback, useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useParams } from 'react-router-dom'
import {
  fetchProject,
  fetchRemoteProject,
  updateProject,
  updateRemoteProject,
} from 'shared/api/projects'
import { getTimestamp } from 'shared/lib/projects'
import { queryClient } from 'shared/queryClient'
import { ProjectData } from 'shared/types/projects'

const PROJECT_BASE_KEY = 'projects'

export const useProjectId = () => {
  const { projectId } = useParams()

  return projectId
}

export const useProject = () => {
  const projectId = useProjectId()

  const query = useQuery<ProjectData | null>({
    queryKey: [PROJECT_BASE_KEY, projectId],
    queryFn: () => fetchProject({ projectId }),
    staleTime: Infinity,
    enabled: Boolean(projectId),
  })

  return query
}

export const useUpdateProjectMutation = () => {
  const projectId = useProjectId()

  const mutation = useMutation({
    mutationKey: [PROJECT_BASE_KEY, projectId],
    mutationFn: (data: ProjectData) =>
      updateProject({
        projectId,
        data,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [PROJECT_BASE_KEY, projectId],
      })
    },
  })

  return mutation
}

interface RefreshRemoteProjectParams {
  projectId: string
  onError: (message: string) => void
  force?: boolean
  signal?: AbortSignal
}

export const refreshRemoteProject = async ({
  projectId,
  signal,
  force = false,
  onError,
}: RefreshRemoteProjectParams) => {
  const localProject = await fetchProject({ projectId })
  const resetLocalQueryCache = () =>
    queryClient.invalidateQueries({
      queryKey: [PROJECT_BASE_KEY, projectId],
    })

  if (force && localProject) {
    updateRemoteProject({
      projectId,
      data: {
        ...localProject,
        saveTimestamp: getTimestamp(),
      },
      signal,
      onError,
    })

    resetLocalQueryCache()

    return
  }

  const remoteProject = await fetchRemoteProject({ projectId, signal, onError })
  const isLocalProjectNewerThanRemote =
    localProject &&
    (remoteProject.saveTimestamp < localProject.saveTimestamp ||
      !remoteProject.saveTimestamp)

  if (isLocalProjectNewerThanRemote) {
    updateRemoteProject({
      projectId,
      data: {
        ...localProject,
        saveTimestamp: getTimestamp(),
      },
      signal,
      onError,
    })
  } else {
    await updateProject({
      projectId,
      data: remoteProject,
      updateTimestamp: false,
    })
  }

  resetLocalQueryCache()
}

const UPDATE_REMOTE_PROJECT_INTERVAL = 60 * 1000

export const useRemoteProjectRefresh = () => {
  const projectId = useProjectId()
  const { enqueueSnackbar } = useSnackbar()
  const { data: projectData } = useProject()
  const [initRequestSended, setInitRequestSended] = useState(false)
  const [initProjectData, setInitProjectData] = useState(projectData)

  const handleError = useCallback(
    (error: string) => enqueueSnackbar(error, { variant: 'error' }),
    [enqueueSnackbar]
  )

  useEffect(() => {
    if (projectId) {
      refreshRemoteProject({ projectId, onError: handleError })
      setInitRequestSended(true)

      return () => {
        setInitRequestSended(false)
      }
    }
  }, [handleError, projectId])

  useEffect(() => {
    if (!initProjectData && projectData) {
      setInitProjectData(projectData)
    } else if (
      projectId &&
      projectData !== initProjectData &&
      initRequestSended
    ) {
      const abortController = new AbortController()
      const timeout = setTimeout(
        () =>
          refreshRemoteProject({
            projectId,
            signal: abortController.signal,
            force: true,
            onError: handleError,
          }),
        UPDATE_REMOTE_PROJECT_INTERVAL
      )

      return () => {
        clearTimeout(timeout)
        abortController.abort()
      }
    }
  }, [projectData, projectId, initRequestSended, initProjectData, handleError])
}
