import { Autocomplete, TextField, createFilterOptions } from '@mui/material'
import { useTags } from 'entities/assessment'

const filter = createFilterOptions<string>()

interface HeuristicSearchProps {
  selectTags: (tags: string[]) => void
  selectedTags: string[]
  placeholder: string
  label: string
  projectId: string
  creatable?: boolean
  fullWidth?: boolean
}

export const HeuristicSearch = ({
  selectTags,
  selectedTags,
  placeholder,
  label,
  projectId,
  creatable,
  fullWidth,
}: HeuristicSearchProps) => {
  const { data: tags = [] } = useTags(projectId)

  const options = [...new Set([...tags, ...selectedTags])]

  return (
    <Autocomplete
      multiple
      options={options}
      getOptionLabel={(option) => option}
      filterSelectedOptions
      sx={
        fullWidth
          ? undefined
          : {
              width: '360px',
            }
      }
      fullWidth={fullWidth}
      size="small"
      value={selectedTags}
      onChange={(_, newValue) => selectTags(newValue)}
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder={placeholder} />
      )}
      filterOptions={
        creatable
          ? (options, params) => {
              const filtered = filter(options, params)

              const { inputValue } = params
              // Suggest the creation of a new value
              const isExisting = options.some((option) => inputValue === option)
              if (inputValue !== '' && !isExisting) {
                filtered.push(inputValue)
              }

              return filtered
            }
          : undefined
      }
    />
  )
}
