import { Datum as HeuristicType } from 'entities/assessment/api-types'
import s from './ReportDetails.module.css'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { ReportScreenCanvas } from '../ReportScreenCanvas'
import { Button, IconButton, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import { RecommendationExamples } from './RecommendationExamples'
import { getParagraphsFromString } from 'shared/lib/string'
import { isEnglishProject } from 'shared/lib/language'
import { useState } from 'react'
import { ProjectData } from 'shared/types/projects'
import CancelIcon from '@mui/icons-material/Cancel'
import { Assessment } from 'entities/assessment'

type Recommendation = Record<string, any>

interface ReportDetailsContentProps {
  projectId: string
  heuristic: HeuristicType | undefined
  assessment: Assessment | undefined
  recommendation: Recommendation | undefined
  isMobile: boolean
  screenId: string
  fragmentId: string
  project: ProjectData
  className?: string
  onClose?: VoidFunction
}

export const ReportDetailsContent = ({
  heuristic,
  projectId,
  recommendation,
  isMobile,
  fragmentId,
  screenId,
  project,
  className,
  onClose,
  assessment,
}: ReportDetailsContentProps) => {
  const [isMaterialsVisible, setMaterialsVisible] = useState(false)

  const { t } = useTranslation()

  const isEnglish = isEnglishProject(projectId)

  const isRuMaterialsEmpty =
    (Array.isArray(heuristic?.field_materials_ru) &&
      heuristic?.field_materials_ru.length === 0) ||
    !Array.isArray(heuristic?.field_materials_ru)

  const heuristicMaterialsValue =
    isEnglish || isRuMaterialsEmpty
      ? heuristic?.field_materials_en
      : heuristic?.field_materials_ru

  const heuristicMaterials: any = Array.isArray(heuristicMaterialsValue)
    ? heuristicMaterialsValue
    : []

  const articles = heuristicMaterials.filter(
    (item: any) => item.field_material_type === 'Article'
  )
  const videos = heuristicMaterials.filter(
    (item: any) => item.field_material_type === 'Video'
  )

  const renderMaterial = (material: any) => (
    <li key={material.id}>
      <a
        target="_blank"
        rel="noreferrer"
        href={material.field_link_url_v1}
        className={s.link}
      >
        {material.field_link_text_v1}
      </a>
    </li>
  )

  const goodExamplesValue = isEnglish
    ? recommendation?.field_fix_examples_en
    : recommendation?.field_fix_examples_ru

  const goodExamples = Array.isArray(goodExamplesValue)
    ? goodExamplesValue.filter(
        (example: any) => Number(example.field_implementation) > 3
      )
    : []
  const badExamples = Array.isArray(goodExamplesValue)
    ? goodExamplesValue.filter(
        (example: any) => Number(example.field_implementation) <= 3
      )
    : []

  const renderRecommendations = () => (
    <>
      <div
        className={classNames(s.heuristicContent, {
          [s.heuristicContentMobile]: isMobile,
          [s.heuristicContentHalfWidth]: isMobile,
        })}
      >
        <div
          className={classNames(s.recommendationHeader, {
            [s.recommendationHeaderMobile]: isMobile,
          })}
        >
          <ThumbUpIcon className={classNames(s.good, s.recommendationIcon)} />
          <Typography variant="h6" fontWeight="bold">
            {t('correct')}
          </Typography>
        </div>
        <Typography>
          {isEnglish
            ? recommendation?.field_recommendation_en
            : recommendation?.field_recommendation_ru}
        </Typography>
        <RecommendationExamples
          key={recommendation?.id}
          examples={goodExamples}
          good
        />
      </div>
      <div
        className={classNames(s.heuristicContent, {
          [s.heuristicContentMobile]: isMobile,
          [s.heuristicContentHalfWidth]: isMobile,
        })}
      >
        <div
          className={classNames(s.recommendationHeader, {
            [s.recommendationHeaderMobile]: isMobile,
          })}
        >
          <ThumbDownIcon className={classNames(s.bad, s.recommendationIcon)} />
          <Typography variant="h6" fontWeight="bold">
            {t('incorrect')}
          </Typography>
        </div>
        <Typography>
          {isEnglish
            ? recommendation?.field_whybad_en
            : recommendation?.field_whybad_ru}
        </Typography>
        <RecommendationExamples
          key={recommendation?.id}
          examples={badExamples}
          good={false}
        />
      </div>
    </>
  )

  const secondLevelTitle = isEnglish
    ? heuristic?.field_he3_link_to_he1_parent?.field_heuristic_2_level_en
    : heuristic?.field_he3_link_to_he1_parent?.title
  const firstLevelTitle = isEnglish
    ? heuristic?.field_he3_he1_list?.field_heuristic_1_level_en
    : heuristic?.field_he3_he1_list?.title

  return (
    <div
      className={classNames(s.wrapper, className, {
        [s.wrapperMobile]: isMobile,
      })}
    >
      <div
        className={classNames(s.fragmentImage, {
          [s.fragmentImageMobile]: isMobile,
        })}
      >
        <ReportScreenCanvas
          screenId={screenId}
          fragmentId={fragmentId}
          assessmentId={assessment?.id}
          project={project}
        />
      </div>
      <div
        className={classNames(s.heuristicAndDescriptionWrapper, {
          [s.heuristicAndDescriptionWrapperMobile]: isMobile,
        })}
      >
        <div>
          {!!assessment?.comment && (
            <>
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{
                  mb: 0.5,
                }}
              >
                {t('description')}
              </Typography>
              <Typography
                sx={{
                  mb: 2,
                }}
              >
                {assessment?.comment}
              </Typography>
            </>
          )}

          {!!assessment?.recommendation && (
            <>
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{
                  mb: 0.5,
                }}
              >
                {t('recommendation')}
              </Typography>
              <Typography
                sx={{
                  mb: 2,
                }}
              >
                {assessment?.recommendation}
              </Typography>
            </>
          )}
        </div>
        {heuristic && (
          <div
            className={classNames(s.heuristic, {
              [s.heuristicMobile]: isMobile,
              [s.onlyHeuristic]: !assessment,
            })}
          >
            <div
              className={classNames(s.heuristicContent, {
                [s.heuristicContentMobile]: isMobile,
                [s.heuristicContentFullWidth]: isMobile,
              })}
            >
              <div>
                <Typography variant="h6" fontWeight="bold">
                  {t('heuristic')}
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="#404BB2"
                  sx={{
                    mb: 1,
                  }}
                >
                  § {firstLevelTitle} → {secondLevelTitle}
                </Typography>
                <Typography className={s.description}>
                  {getParagraphsFromString(
                    String(
                      isEnglish
                        ? heuristic?.field_description_en
                        : heuristic?.field_description_ru
                    )
                  )?.map((paragraph, index) => (
                    <>
                      {index !== 0 && (
                        <>
                          <br />
                        </>
                      )}
                      {paragraph}
                    </>
                  ))}
                </Typography>
              </div>
              {heuristicMaterials.length > 0 && (
                <div
                  className={classNames(s.materialsHeader, {
                    [s.materialsHeaderMobile]: isMobile,
                  })}
                >
                  <Typography fontWeight="bold">
                    {t('relatedMaterials')} ({heuristicMaterials.length})
                  </Typography>
                  <Button
                    variant="text"
                    className={classNames(s.materialsButton, {
                      [s.materialsButtonMobile]: isMobile,
                    })}
                    endIcon={
                      <ExpandMoreIcon
                        style={{
                          transform: isMaterialsVisible
                            ? 'rotate(180deg)'
                            : 'none',
                        }}
                      />
                    }
                    onClick={() => setMaterialsVisible(!isMaterialsVisible)}
                  >
                    {t('show')}
                  </Button>
                </div>
              )}
              {isMaterialsVisible && (
                <div>
                  <Typography>{t('articles')}</Typography>
                  <ol className={s.list}>{articles?.map(renderMaterial)}</ol>
                  {videos.length > 0 && (
                    <>
                      <Typography>{t('videos')}</Typography>
                      <ol className={s.list}>{videos?.map(renderMaterial)}</ol>
                    </>
                  )}
                </div>
              )}
              <Typography
                sx={{
                  mt: 2,
                }}
                fontWeight="bold"
              >
                {t('tags')}
              </Typography>
              {heuristic && (
                <Typography>
                  {(isEnglish
                    ? heuristic?.field_field_htags_en
                    : (heuristic?.field_htags as any)
                  )
                    ?.map((tag: any) => tag.name)
                    .join(', ')}
                </Typography>
              )}
            </div>
            {isMobile ? (
              <div className={s.mobileRecommendationsWrapper}>
                {renderRecommendations()}
              </div>
            ) : (
              renderRecommendations()
            )}
          </div>
        )}
      </div>

      {onClose && (
        <IconButton onClick={onClose} className={s.closeButton}>
          <CancelIcon
            fontSize="large"
            sx={{
              fill: '#000',
            }}
          />
        </IconButton>
      )}
    </div>
  )
}
