import { ProjectData, RemoteProjectMeta } from 'shared/types/projects'
import { axiosInstance } from './axios'
import {
  getProjectKey,
  getTimestamp,
  isValidProjectData,
} from 'shared/lib/projects'
import { createUUID } from 'shared/lib/uuid'
import { getInitialFlow } from 'entities/flow/fixtures'
import { getDefaultRole } from 'entities/flow'
import { getInitialScreenFragments } from 'entities/assessment'
import { ProjectResponseData } from './projects-api-types'
import i18n from 'shared/i18n/i18n'

interface GetInitialProjectDataParams {
  projectId: string
}

export const getInitialProjectData = ({
  projectId,
}: GetInitialProjectDataParams): ProjectData => {
  const intitialScreenId = createUUID()

  return {
    id: projectId,
    flow: getInitialFlow(intitialScreenId),
    roles: [getDefaultRole()],
    screenFragments: {
      [intitialScreenId]: getInitialScreenFragments(),
    },
    transitionComponents: [],
    saveTimestamp: getTimestamp(),
  }
}

interface UpdateProjectParams {
  projectId: string | undefined
  data: ProjectData
  updateTimestamp?: boolean
}

export const updateProject = async ({
  data,
  projectId,
  updateTimestamp = true,
}: UpdateProjectParams) => {
  if (!projectId) {
    throw new Error('Empty projectId')
  }

  const newData: ProjectData = updateTimestamp
    ? {
        ...data,
        saveTimestamp: getTimestamp(),
      }
    : data

  localStorage.setItem(getProjectKey(projectId), JSON.stringify(newData))
}
interface UpdateRemoteProjectRequest {
  data: {
    type: 'projects'
    id: string
    attributes: {
      field_project_data: string
    }
  }
}

interface UpdateRemoteProjectParams {
  projectId: string | undefined
  data: ProjectData
  onError: (message: string) => void
  signal?: AbortSignal
}

export const updateRemoteProject = async ({
  data,
  projectId,
  signal,
  onError,
}: UpdateRemoteProjectParams) => {
  if (!projectId) {
    throw new Error('Empty projectId')
  }

  try {
    await axiosInstance.patch<void, any, UpdateRemoteProjectRequest>(
      `https://backend.prodmaps.com/en/api135a/node/project/${projectId}`,
      {
        data: {
          type: 'projects',
          id: projectId,
          attributes: {
            field_project_data: JSON.stringify(data),
          },
        },
      },
      {
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
        },
        signal,
      }
    )
  } catch (e) {
    onError(i18n.t('saveProjectError'))

    throw e
  }
}

interface FetchProjectParams {
  projectId: string | undefined
}

export const fetchProject = async ({
  projectId,
}: FetchProjectParams): Promise<ProjectData | null> => {
  if (!projectId) {
    throw new Error('Empty projectId')
  }

  const projectData = localStorage.getItem(getProjectKey(projectId))

  if (!projectData) {
    return null
  }

  const parsedProjectData: ProjectData = JSON.parse(projectData)

  return parsedProjectData
}

interface FetchRemoteProjectResponse {
  data: ProjectResponseData
}

interface FetchRemoteProjectParams {
  projectId: string | undefined
  onError?: (message: string) => void
  signal?: AbortSignal
}

export const fetchRemoteProject = async ({
  projectId,
  signal,
  onError,
}: FetchRemoteProjectParams): Promise<ProjectData> => {
  if (!projectId) {
    throw new Error('Empty projectId')
  }

  try {
    const response = await axiosInstance.get<FetchRemoteProjectResponse>(
      `https://backend.prodmaps.com/en/api135a/node/project/${projectId}?include=field_scen_company,field_scen_product_parent,field_scen_roles&jsonapi_include=1&page=${getTimestamp()}`,
      {
        headers: {
          Accept: 'application/vnd.api+json',
        },
        signal,
      }
    )

    const parsedProjectData: ProjectData = JSON.parse(
      response.data.data.field_project_data
    )

    if (isValidProjectData(parsedProjectData)) {
      const projectMeta: RemoteProjectMeta = {
        platform: response.data.data.field_device,
        product: response.data.data.field_scen_product_parent.title,
        role: response.data.data.field_scen_roles.title,
        title: response.data.data.title,
        version: response.data.data.field_version,
        language: response.data.data.field_language,
      }

      localStorage.setItem(`metaData-${projectId}`, JSON.stringify(projectMeta))

      i18n.changeLanguage(
        projectMeta.language.toLowerCase() === 'en' ? 'en' : 'ru'
      )

      return parsedProjectData
    } else {
      await updateRemoteProject({
        projectId,
        data: getInitialProjectData({ projectId }),
        onError: onError || (() => {}),
      })

      return fetchRemoteProject({ projectId, onError })
    }
  } catch (e) {
    onError?.(i18n.t('updateProjectError'))

    throw e
  }
}
