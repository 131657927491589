import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { Flow } from './flow'
import { Assessment } from './assessment'
import { ReportPage } from 'widgets/report/ui/ReportPage'

const router = createBrowserRouter([
  {
    path: '/:projectId',
    element: <Flow />,
  },
  {
    path: '/:projectId/screen/:screenId',
    element: <Assessment />,
  },
  {
    path: '/a/:projectId',
    element: <Flow />,
  },
  {
    path: '/a/:projectId/screen/:screenId',
    element: <Assessment />,
  },
  {
    path: '/project/:projectId',
    element: <Flow />,
  },
  {
    path: '/project/:projectId/screen/:screenId',
    element: <Assessment />,
  },
  {
    path: '/r/:projectId',
    element: <ReportPage />,
  },
  {
    path: '/r/:screenNumber/:projectId',
    element: <ReportPage />,
  },
  {
    path: '/r/:screenNumber/:screenVariantNumber/:projectId',
    element: <ReportPage />,
  },
  {
    path: '/r/:screenNumber/:screenVariantNumber/:screenVariantFragmentNumber/:projectId',
    element: <ReportPage />,
  },
  {
    path: '/r/:screenNumber/:screenVariantNumber/:screenVariantFragmentNumber/:assessmentId/:projectId',
    element: <ReportPage />,
  },
  {
    path: '/rm/:projectId',
    element: <ReportPage />,
  },
])

export const Routes = () => <RouterProvider router={router} />
