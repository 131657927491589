import {
  Assessment,
  AssessmentFilterType,
  FilterAttribute,
  HeuristicFilterType,
  getAssessmentAttributes,
} from 'entities/assessment'
import StarIcon from '@mui/icons-material/Star'
import i18n from 'shared/i18n/i18n'

export const getHeuristicFilterAttributes =
  (): FilterAttribute<HeuristicFilterType>[] => [
    {
      type: 'all',
      label: i18n.t('all'),
    },
    {
      type: 'favorite',
      label: <StarIcon />,
    },
  ]

export const getAssessmentFilterAttributes = (
  assessments: Assessment[]
): FilterAttribute<AssessmentFilterType>[] => [
  {
    type: 'all',
    label: i18n.t('all'),
  },
  {
    type: 'ux-good',
    label: assessments.filter((item) => item.findType === 'ux-good').length,
    color: getAssessmentAttributes()['ux-good'].color,
  },
  {
    type: 'ux-problem',
    label: assessments.filter((item) => item.findType === 'ux-problem').length,
    color: getAssessmentAttributes()['ux-problem'].color,
  },
  {
    type: 'task',
    label: assessments.filter((item) => item.findType === 'task').length,
    color: getAssessmentAttributes().task.color,
  },
  {
    type: 'bug',
    label: assessments.filter((item) => item.findType === 'bug').length,
    color: getAssessmentAttributes().bug.color,
  },
]
