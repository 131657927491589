import { Button, ThemeProvider, Typography } from '@mui/material'
import s from './FindSelect.module.css'
import { Option } from './types'
import { theme } from './theme'
import classNames from 'classnames'

interface FindSelectProps {
  value: string | null
  onChange: (value: string) => void
  options: Option[]
  label: string
  error: boolean
  disabled?: boolean
}

export const FindSelect = ({
  onChange,
  options,
  value,
  label,
  error,
  disabled = false,
}: FindSelectProps) => {
  return (
    <ThemeProvider theme={theme}>
      <div
        className={classNames(s.wrapper, {
          [s.disabled]: disabled,
        })}
      >
        <Typography
          className={classNames(s.label, {
            [s.labelError]: error,
          })}
          variant="subtitle2"
        >
          {label}
        </Typography>
        <div className={s.buttons}>
          {options.map((option) => (
            <Button
              className={s.button}
              variant={value === option.value ? 'contained' : 'outlined'}
              key={option.name}
              onClick={() => onChange(option.value)}
            >
              {option.name}
            </Button>
          ))}
        </div>
      </div>
    </ThemeProvider>
  )
}
