import classNames from 'classnames'
import { FilterAttribute } from 'entities/assessment'

import styles from './AssessmentPanelFilter.module.css'

interface AssessmentPanelFilterProps {
  selectedFilters: string[]
  selectFilters: (value: string[]) => void
  filterAttributes: FilterAttribute<string>[]
  multiple?: boolean
}

export const AssessmentPanelFilter = ({
  selectFilters,
  selectedFilters,
  filterAttributes,
  multiple,
}: AssessmentPanelFilterProps) => {
  return (
    <div className={styles.wrapper}>
      {filterAttributes.map((filterAttribute) => (
        <div
          key={filterAttribute.type}
          onClick={() => {
            if (filterAttribute.type === 'all') {
              selectFilters(['all'])
            } else {
              if (multiple) {
                const newFilters = (
                  selectedFilters.includes(filterAttribute.type)
                    ? selectedFilters.filter(
                        (item) => item !== filterAttribute.type
                      )
                    : selectedFilters.concat(filterAttribute.type)
                ).filter((item) => item !== 'all')

                if (
                  newFilters.length === 0 ||
                  newFilters.length === filterAttributes.length
                ) {
                  selectFilters(['all'])
                } else {
                  selectFilters(newFilters)
                }
              } else {
                selectFilters([filterAttribute.type])
              }
            }
          }}
          className={classNames(styles.filter, {
            [styles.filterActive]: selectedFilters.includes(
              filterAttribute.type
            ),
          })}
          style={
            filterAttribute.color
              ? {
                  backgroundColor: filterAttribute.color,
                }
              : undefined
          }
        >
          {filterAttribute.label}
        </div>
      ))}
    </div>
  )
}
