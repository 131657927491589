import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { RemoteProjectMeta } from 'shared/types/projects'
import s from './ScenarioInfo.module.css'
import WidgetsIcon from '@mui/icons-material/Widgets'
import LanguageIcon from '@mui/icons-material/Language'
import PersonIcon from '@mui/icons-material/Person'
import TabletAndroidIcon from '@mui/icons-material/TabletAndroid'
import ForkRightIcon from '@mui/icons-material/ForkRight'
import classNames from 'classnames'

interface ScenarioInfoProps {
  projectId: string
  children?: React.ReactNode
  withoutBottomBorder?: boolean
}

export const ScenarioInfo = ({
  projectId,
  withoutBottomBorder,
  children,
}: ScenarioInfoProps) => {
  const encodedProjectMeta = localStorage.getItem(`metaData-${projectId}`)

  const { t } = useTranslation()

  if (!encodedProjectMeta) {
    return null
  }

  const decodedProjectMeta: RemoteProjectMeta = JSON.parse(encodedProjectMeta)

  const isMobile =
    decodedProjectMeta.platform.includes('obile') ||
    decodedProjectMeta.platform.includes('обил')

  return (
    <div
      className={classNames(s.wrapper, {
        [s.withoutBottomBorder]: withoutBottomBorder,
      })}
    >
      <div className={s.inputWrapper}>
        <FormControl className={s.formControl}>
          <InputLabel id="scenario-select-label">{t('scenario')}</InputLabel>
          <Select
            size="small"
            labelId="scenario-select-label"
            value={1}
            label={t('scenario')}
          >
            <MenuItem value={1}>{decodedProjectMeta.title}</MenuItem>
          </Select>
        </FormControl>
        {children}
      </div>
      <div className={s.items}>
        <div className={s.item}>
          <WidgetsIcon />
          <Typography variant="body2">{decodedProjectMeta.product}</Typography>
        </div>
        <div className={s.item}>
          {isMobile ? <TabletAndroidIcon /> : <LanguageIcon />}
          <Typography variant="body2">{decodedProjectMeta.platform}</Typography>
        </div>
        <div className={s.item}>
          <PersonIcon />
          <Typography variant="body2">{decodedProjectMeta.role}</Typography>
        </div>
        <div className={s.item}>
          <ForkRightIcon />
          <Typography variant="body2">{decodedProjectMeta.version}</Typography>
        </div>
      </div>
    </div>
  )
}
