import { useSnackbar } from 'notistack'
import { Assessment, HeuristicsAndTags, ScreenFragment } from './types'
import { createUUID } from 'shared/lib/uuid'
import { Dimensions, XYPosition } from 'reactflow'
import { useProject, useUpdateProjectMutation } from 'shared/model/projects'
import { useQuery } from '@tanstack/react-query'
import { fetchHeuristics } from './api'
import { useEffect } from 'react'
import { isEnglishProject } from 'shared/lib/language'
import i18n from 'shared/i18n/i18n'

export const getInitialScreenFragments = (): ScreenFragment[] => [
  {
    height: 0,
    width: 0,
    id: createUUID(),
    name: i18n.t('mainScreen'),
    assessments: [],
    position: {
      x: 0,
      y: 0,
    },
    root: true,
  },
]

interface UseScreenFragmentsParams {
  screenId: string
}

export const useScreenFragments = ({ screenId }: UseScreenFragmentsParams) => {
  const { data } = useProject()
  return data?.screenFragments?.[screenId]
}

interface DeleteScreenFragmentParams {
  fragmentId: string
  screenId: string
  onSuccess: VoidFunction
}

export const useDeleteScreenFragment = () => {
  const { mutateAsync } = useUpdateProjectMutation()
  const { data: projectData } = useProject()

  return ({ fragmentId, screenId, onSuccess }: DeleteScreenFragmentParams) => {
    if (projectData) {
      mutateAsync(
        {
          ...projectData,
          screenFragments: {
            ...projectData.screenFragments,
            [screenId]: projectData.screenFragments[screenId]?.filter(
              (fragment) => fragment.id !== fragmentId
            ),
          },
        },
        {
          onSuccess,
        }
      )
    }
  }
}

export interface AddScreenFragmentParams {
  position: XYPosition
  dimensions: Dimensions
  screenId: string
  onSuccess: (id: string) => void
}

export const useAddScreenFragment = () => {
  const { data: projectData } = useProject()
  const { mutateAsync } = useUpdateProjectMutation()

  return ({
    dimensions,
    onSuccess,
    position,
    screenId,
  }: AddScreenFragmentParams) => {
    if (projectData) {
      const id = createUUID()

      const screenFragments = projectData.screenFragments[screenId] || []

      const newFragment: ScreenFragment = {
        id,
        height: dimensions.height,
        width: dimensions.width,
        position,
        assessments: [],
        name: `${i18n.t('newFragment')} #${
          Number(screenFragments?.length) + 1
        }`,
      }

      mutateAsync(
        {
          ...projectData,
          screenFragments: {
            ...projectData.screenFragments,
            [screenId]:
              projectData.screenFragments[screenId]?.concat(newFragment),
          },
        },
        {
          onSuccess: () => onSuccess(id),
        }
      )
    }
  }
}

export interface UpdateScreenFragmentParams {
  fragmentId: string
  position: XYPosition
  dimensions: Dimensions
  screenId: string
  onSuccess: VoidFunction
}

export const useUpdateScreenFragment = () => {
  const { data: projectData } = useProject()
  const { mutateAsync } = useUpdateProjectMutation()

  return ({
    dimensions,
    fragmentId,
    position,
    screenId,
    onSuccess,
  }: UpdateScreenFragmentParams) => {
    if (projectData) {
      mutateAsync(
        {
          ...projectData,
          screenFragments: {
            ...projectData.screenFragments,
            [screenId]: projectData.screenFragments[screenId].map((item) =>
              item.id === fragmentId
                ? {
                    ...item,
                    height: dimensions.height,
                    width: dimensions.width,
                    position,
                  }
                : item
            ),
          },
        },
        {
          onSuccess,
        }
      )
    }
  }
}

interface UpdateScreenFragmentNameParams {
  fragmentId: string
  screenId: string
  name: string
}

export const useUpdateScreenFragmentName = () => {
  const { data: projectData } = useProject()
  const { mutateAsync } = useUpdateProjectMutation()

  return ({ fragmentId, name, screenId }: UpdateScreenFragmentNameParams) => {
    if (projectData) {
      mutateAsync({
        ...projectData,
        screenFragments: {
          ...projectData.screenFragments,
          [screenId]: projectData.screenFragments[screenId].map((item) =>
            item.id === fragmentId
              ? {
                  ...item,
                  name,
                }
              : item
          ),
        },
      })
    }
  }
}

export const useHeuristicsAndTags = (projectId: string | undefined) => {
  const isEnglish = isEnglishProject(projectId)

  const query = useQuery({
    queryKey: ['heuristics', isEnglish],
    queryFn: fetchHeuristics,
    staleTime: Infinity,
    select: (data): HeuristicsAndTags => {
      const heuristics: HeuristicsAndTags['heuristics'] = (data as any)
        .filter((heuristicObject: any) => {
          const tags = isEnglish
            ? heuristicObject.field_field_htags_en
            : heuristicObject.field_htags

          return Array.isArray(tags) && tags.length !== 0
        })
        .map((heuristicObject: any) => {
          const tags = isEnglish
            ? heuristicObject.field_field_htags_en
            : heuristicObject.field_htags

          return {
            id: heuristicObject.id,
            description: isEnglish
              ? heuristicObject.field_description_en
              : heuristicObject.field_description_ru,
            name: String(
              isEnglish
                ? heuristicObject.field_heuristic_3_level_en
                : heuristicObject.title
            ),
            tags: Array.isArray(tags)
              ? tags.map((heuristicObjectTag: any) => heuristicObjectTag.name)
              : [],
            firstLevelHeuristicName: String(
              isEnglish
                ? heuristicObject.field_he3_he1_list?.field_heuristic_1_level_en
                : heuristicObject.field_he3_he1_list?.title
            ),
            secondLevelHeuristicName: String(
              isEnglish
                ? heuristicObject.field_he3_link_to_he1_parent
                    ?.field_heuristic_2_level_en
                : heuristicObject.field_he3_link_to_he1_parent?.title
            ),
            favorite: Boolean(heuristicObject.field_default_favorite),
            nameNegative: String(
              isEnglish
                ? heuristicObject.field_negative_name_en
                : heuristicObject.field_negative_name_ru
            ),
            evaluationArea: heuristicObject.field_evaluation_area,
          }
        })

      const tags: HeuristicsAndTags['tags'] = [
        ...heuristics.reduce((acc: Set<string>, currentHeuristic) => {
          currentHeuristic.tags.forEach((tag) => acc.add(tag))

          return acc
        }, new Set<string>()),
      ]

      return {
        heuristics,
        tags,
        heuristicsResponse: data,
      }
    },
  })

  return query
}

interface AddAssessmentParams {
  assessment: Omit<Assessment, 'id'>
  screenId: string
  fragmentId: string
}

export const useAddAssessment = () => {
  const { data: projectData } = useProject()
  const { mutateAsync } = useUpdateProjectMutation()

  return ({ assessment, screenId, fragmentId }: AddAssessmentParams) => {
    if (projectData) {
      mutateAsync({
        ...projectData,
        screenFragments: {
          ...projectData.screenFragments,
          [screenId]: projectData.screenFragments[screenId].map((fragment) =>
            fragment.id === fragmentId
              ? {
                  ...fragment,
                  assessments: fragment.assessments.concat({
                    ...assessment,
                    id: createUUID(),
                  }),
                }
              : fragment
          ),
        },
      })
    }
  }
}

interface DeleteAssessmentParams {
  assessmentId: string
  screenId: string
  fragmentId: string
}

export const useDeleteAssessment = () => {
  const { data: projectData } = useProject()
  const { mutateAsync } = useUpdateProjectMutation()

  return ({ assessmentId, fragmentId, screenId }: DeleteAssessmentParams) => {
    if (projectData) {
      mutateAsync({
        ...projectData,
        screenFragments: {
          ...projectData.screenFragments,
          [screenId]: projectData.screenFragments[screenId].map((fragment) =>
            fragment.id === fragmentId
              ? {
                  ...fragment,
                  assessments: fragment.assessments.filter(
                    (item) => item.id !== assessmentId
                  ),
                }
              : fragment
          ),
        },
      })
    }
  }
}

interface EditAssessmentParams {
  assessment: Assessment
  screenId: string
  fragmentId: string
}

export const useEditAssessment = () => {
  const { data: projectData } = useProject()
  const { mutateAsync } = useUpdateProjectMutation()

  return ({ assessment, screenId, fragmentId }: EditAssessmentParams) => {
    if (projectData) {
      mutateAsync({
        ...projectData,
        screenFragments: {
          ...projectData.screenFragments,
          [screenId]: projectData.screenFragments[screenId].map((fragment) =>
            fragment.id === fragmentId
              ? {
                  ...fragment,
                  assessments: fragment.assessments.map((item) =>
                    item.id === assessment.id ? assessment : item
                  ),
                }
              : fragment
          ),
        },
      })
    }
  }
}

export const useTags = (projectId: string) => {
  const { data } = useHeuristicsAndTags(projectId)

  return {
    data: data?.tags,
  }
}

interface UseHeuristicsParams {
  tags: string[]
  projectId: string | undefined
}

export const useHeuristics = ({ tags, projectId }: UseHeuristicsParams) => {
  const { data, isError } = useHeuristicsAndTags(projectId)
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(i18n.t('fetchHeuristicsError'), {
        variant: 'error',
      })
    }
  }, [enqueueSnackbar, isError])

  return data?.heuristics.filter((heuristic) =>
    tags.every((tag) => heuristic.tags.includes(tag))
  )
}
