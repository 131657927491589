import {
  FlowTransitionNodeProps,
  TRANSITION_HEIGHT,
  TRANSITION_WIDTH,
  useCreateNewTransitionResult,
  useCreateNewTransitionAction,
  useFlow,
} from 'entities/flow'

import styles from './FlowTransitionNode.module.css'
import classNames from 'classnames'
import { useState } from 'react'
import { TransitionEdit } from '../TransitionForm/TransitionEdit/TransitionEdit'
import { TransitionComponentPanel } from '../TransitionComponentPanel/TransitionComponentPanel'
import { useTransitionContext } from '../model'
import { Handle, Position } from 'reactflow'
import { useHasWritePermission } from 'entities/permissions'
import i18n from 'shared/i18n/i18n'
import { useProjectContext } from 'features/head/context'

export const FlowTransitionNode = ({
  data,
  id,
  isConnectable,
}: FlowTransitionNodeProps) => {
  const hasWritePermission = useHasWritePermission()

  const [editOpen, setEditOpen] = useState(false)
  const { data: flow } = useFlow()
  const { setTransition } = useTransitionContext()
  const empty = !data.actionId
  const transition = {
    ...data,
    id,
  }

  const createNewTransitionAction = useCreateNewTransitionAction()
  const createNewTransitionResult = useCreateNewTransitionResult()

  const { isAssessment } = useProjectContext()

  return (
    <>
      <div
        className={classNames(styles.wrapper, {
          [styles['empty-wrapper']]: empty,
        })}
        style={{
          height: TRANSITION_HEIGHT,
          width: TRANSITION_WIDTH,
        }}
        onClick={
          empty && hasWritePermission && !isAssessment
            ? () => setEditOpen(true)
            : undefined
        }
      >
        {empty ? (
          `+ ${i18n.t('transition')}`
        ) : (
          <TransitionComponentPanel transition={transition} />
        )}
        {!empty && hasWritePermission && !isAssessment && (
          <div
            onClick={(event) => {
              event.stopPropagation()
              createNewTransitionAction({
                fromTransitionPosition: transition.position,
                onSuccess: (newTransition) => setTransition(newTransition),
                flow,
              })
            }}
            className={styles.addActionWrapper}
          >
            <div className={styles.addAction}>{i18n.t('addAction')}</div>
          </div>
        )}
        {!empty && hasWritePermission && !isAssessment && (
          <div
            onClick={(event) => {
              event.stopPropagation()
              createNewTransitionResult({
                fromTransition: transition,
              })
            }}
            className={styles.addResultWrapper}
          >
            <div className={styles.addResult}>{i18n.t('addResult')}</div>
          </div>
        )}
      </div>
      <Handle
        style={{
          opacity: 0,
        }}
        type="source"
        position={Position.Right}
      />
      {editOpen && hasWritePermission && !isAssessment && (
        <TransitionEdit
          isOpen
          onClose={() => setEditOpen(false)}
          transition={transition}
        />
      )}
    </>
  )
}
