import { Button, Link, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import s from './ProjectHead.module.css'
import EastIcon from '@mui/icons-material/East'
import { ScenarioInfo } from 'widgets/report/ui/ScenarioInfo'
import { useProjectId } from 'shared/model/projects'
import classNames from 'classnames'
import { useProjectContext } from './context'
import { useNavigate } from 'react-router-dom'

export const ProjectHead = () => {
  const projectId = useProjectId()

  const { t } = useTranslation()

  const { isMaster, isAssessment, toggleMenu, setAssessment } =
    useProjectContext()

  const navigate = useNavigate()

  return (
    <div className={s.wrapper}>
      <div className={s.left}>
        <Link
          target="_blank"
          href="https://backend.prodmaps.com/en/admin/scenarios"
        >
          {t('home')}
        </Link>
        <EastIcon className={s.icon} />
        {projectId && (
          <ScenarioInfo withoutBottomBorder projectId={projectId}>
            {isMaster ? (
              <>
                <Button
                  className={classNames(s.leftButton, s.button)}
                  variant={isAssessment ? 'outlined' : 'contained'}
                  onClick={() => setAssessment(false)}
                >
                  {t('masterScenario')}
                </Button>
                <Button
                  className={s.button}
                  variant={isAssessment ? 'contained' : 'outlined'}
                  onClick={() => setAssessment(true)}
                >
                  {t('finalAssessment')}
                </Button>
              </>
            ) : (
              <>
                <Button
                  className={classNames(s.leftButton, s.button)}
                  variant={isAssessment ? 'contained' : 'outlined'}
                  onClick={() => {
                    navigate(`/a/${projectId}`)
                    setAssessment(true)
                  }}
                >
                  {t('assessment')}
                </Button>
                <Button
                  className={s.button}
                  variant={isAssessment ? 'outlined' : 'contained'}
                  onClick={() => {
                    navigate(`/r/${projectId}`)
                    setAssessment(false)
                  }}
                >
                  {t('report')}
                </Button>
              </>
            )}
          </ScenarioInfo>
        )}
      </div>
      <div onClick={toggleMenu} className={s.right}>
        <Typography>Лидовин Аурельевич Трегульо</Typography>
        <Typography className={s.email} variant="body2">
          keka.peka@mek.com
        </Typography>
      </div>
    </div>
  )
}
