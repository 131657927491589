import { Button, ThemeProvider, Typography } from '@mui/material'
import s from './AssessmentHead.module.css'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'
import { theme } from './theme'
import {
  DEFAULT_OFFSET,
  TRANSITION_WIDTH,
  getNearestActionIdInColumn,
} from 'entities/flow'
import { useProject } from 'shared/model/projects'

interface AssessmentHeadProps {
  screenId: string
  goToFlow: VoidFunction
}

export const AssessmentHead = ({ screenId, goToFlow }: AssessmentHeadProps) => {
  const { t } = useTranslation()

  const { data: project } = useProject()

  const screen = project?.flow.screens.find((item) => item.id === screenId)

  if (!project || !screen) return null

  const nearestActionId = getNearestActionIdInColumn({
    transitions: project.flow.transitions,
    position: {
      x: screen.position.x - DEFAULT_OFFSET - TRANSITION_WIDTH,
      y: screen.position.y - 1,
    },
  })

  const actionName = project.transitionComponents.find(
    (component) => component.id === nearestActionId
  )?.name

  return (
    <ThemeProvider theme={theme}>
      <div className={s.wrapper}>
        <div>
          <Typography className={s.actionName} variant="body2">
            {actionName}
          </Typography>
          <Typography className={s.screenName} variant="h6">
            {screen.name}
          </Typography>
        </div>
        <Button onClick={goToFlow} startIcon={<CloseIcon className={s.icon} />}>
          {t('closePage')}
        </Button>
      </div>
    </ThemeProvider>
  )
}
