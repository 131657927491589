import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material'
import {
  Report,
  ReportAssessmentType,
  ReportFilterType,
  ReportScreenVariantFragment,
} from 'widgets/report/types'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { ReportHeuristic } from '../ReportHeuristic'

import s from './ReportFragment.module.css'
import { useEffect, useState } from 'react'
import { ReportAssessmentFilter } from '../ReportAssessmentFilter/ReportAssessmentFilter'
import { ReportFind } from '../ReportFind'

interface ReportFragmentProps {
  expanded: boolean
  onExpandedChange: VoidFunction
  formattedNumber: string
  fragment: ReportScreenVariantFragment
  selectedAssessmentId: string | null
  setSelectedAssessmentId: (value: string | null) => void
  report: Report
  assessmentFilter: ReportFilterType
}

export const ReportFragment = ({
  expanded,
  formattedNumber,
  fragment,
  onExpandedChange,
  selectedAssessmentId,
  report,
  setSelectedAssessmentId,
  assessmentFilter,
}: ReportFragmentProps) => {
  const isAllFilter = assessmentFilter === 'all'

  const filteredAssessments = isAllFilter
    ? fragment.assessments
    : fragment.assessments.filter((assessment) =>
        assessmentFilter.includes(assessment.findType as ReportFilterType)
      )

  if (!filteredAssessments.length) return null

  return (
    <Accordion
      className={s.accordion}
      expanded={expanded}
      onChange={onExpandedChange}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            style={
              expanded
                ? {
                    fill: '#2C9BF0',
                  }
                : undefined
            }
          />
        }
        className={s.accordionSummary}
      >
        <div className={s.accordionSummaryContent}>
          <div>
            <Typography variant="subtitle2" color="gray">
              {formattedNumber}
            </Typography>
            <Typography variant="h6" className={s.title}>
              {fragment.name}
            </Typography>
          </div>
          <ReportAssessmentFilter
            assessmentFilter={assessmentFilter}
            assessments={fragment.totalAssessments}
          />
        </div>
      </AccordionSummary>
      <AccordionDetails className={s.accordionDetails}>
        {filteredAssessments.map((assessment) => (
          <ReportFind
            key={assessment.id}
            active={assessment.id === selectedAssessmentId}
            heuristics={report.heuristics}
            assessment={assessment}
            projectId={report.projectId}
            onClick={() =>
              setSelectedAssessmentId(
                assessment.id === selectedAssessmentId ? null : assessment.id
              )
            }
          />
        ))}
      </AccordionDetails>
    </Accordion>
  )
}
