import { AssessmentAttribute, FindType } from './types'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import DoneIcon from '@mui/icons-material/Done'
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'

export const getAssessmentAttributes = (): Record<
  FindType | 'na',
  AssessmentAttribute
> => ({
  'ux-problem': {
    color: '#F2D0C9',
    icon: <PriorityHighIcon />,
  },
  'ux-good': {
    color: '#DFECD1',
    icon: <DoneIcon />,
  },
  bug: {
    color: '#F4EAC5',
    icon: <SentimentNeutralIcon />,
  },
  task: {
    color: '#d9daea',
    icon: <DoNotDisturbIcon />,
  },
  na: {
    color: '#d2d2d2',
    icon: <DoNotDisturbIcon />,
  },
})

export const assessmentTypes: FindType[] = [
  'ux-good',
  'ux-problem',
  'task',
  'bug',
]
